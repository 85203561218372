<template>
  <div>
    <div class="overlay">&nbsp;</div>
    <a-card class="verify-phone-form-card">
      <ValidationObserver ref="observer">
        <a-form
          :label-width="80"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(handleSubmit)"
        >
          <h1 class="title">{{ labels.title }}</h1>
          <h2 class="subtitle">{{ labels.subtitle }}</h2>

          <!-- // TODO: Card styling -->
          <p class="description">
            {{
              labels[sent ? "after" : "before"].replace(
                "{phone}",
                "+18 ******" + loggedUser.whatsapp.substr(6, 4)
              )
            }}
          </p>

          <Input
            v-if="sent"
            v-model="form.code"
            type="text"
            rules="required"
            vid="code"
            :placeholder="labels.form.code.placeholder"
            size="large"
            :allowClear="true"
            :label="labels.form.code.label"
          />
          <a-form-item style="text-align: right">
            <a-button
              type="default"
              html-type="submit"
              :loading="submitting"
              :disabled="submitting"
              size="large"
              class="btn-reset"
              @click.prevent="validate().then(handleSubmit)"
            >
              <span>{{ sent ? labels.form.submit : labels.form.send }}</span>
            </a-button>
          </a-form-item>
        </a-form>
      </ValidationObserver>
    </a-card>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Input from "@/components/core/VeeValidateForm/Input";
import labels from "@/utils/labels";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
const initialState = {
  code: "",
};
export default {
  name: "VerifyPhoneForm",
  components: {
    ValidationObserver,
    Input,
  },
  data() {
    return {
      form: _.cloneDeep(initialState),
      submitting: false,
      labels: labels.verifyPhoneForm,
      sent: false,
    };
  },
  methods: {
    ...mapActions("clientAuth", ["handleVerifyPhone"]),
    async handleSubmit() {
      try {
        await this.handleVerifyPhone(this.sent ? this.form : false);
        this.$success({
          title: this.sent
            ? this.labels.form.success
            : this.labels.form.sent_success,
          centered: true,
          onOk: () => {
            this.form = _.cloneDeep(initialState);
            this.$refs.observer.reset();
            if (this.sent) {
              this.$nextTick(() => {
                this.$router.push({ name: "welcome" });
              });
            }
            this.sent = true;
          },
        });
      } catch (error) {
        this.$error({
          title: this.labels.form.error,
          content: this.sent
            ? this.labels.form.error_code
            : error?.response?.data?.message ||
              error?.response?.message ||
              error.message,
          centered: true,
        });
      } finally {
        this.submitting = false;
      }
    },
  },
  computed: {
    ...mapGetters("clientAuth", ["getLoggedUser"]),
    loggedUser() {
      return this.getLoggedUser;
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.6);
}
.verify-phone-form-card {
  background-color: color(c-secondary);
  border-color: color(c-hover-light);
  box-shadow: 0 0 15px rgba(55, 245, 249, 35%);
  animation: shadowGlow 2s infinite ease;
  padding: 3.125rem;
  border-radius: 10px;
  z-index: 4;
  @media screen and (max-width: $screen-mobile) {
    padding: 2.125rem 1rem;
  }
  .title {
    font-family: "Exo 2", sans-serif;
    color: color(--white);
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
    word-break: break-word;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .subtitle {
    font-family: "Exo 2", sans-serif;
    color: color(--white);
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
    word-break: break-word;
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
  .description {
    font-family: "Exo 2", sans-serif;
    color: color(--white);
    margin: 0;
    word-break: break-word;
    margin-bottom: 1rem;
  }
  .ant-card-body {
    padding: 0;
  }
  .ant-form label {
    font-size: 0.875rem;
    color: color(--white);
    font-family: "Roboto", sans-serif;
  }
  .ant-input {
    background-color: color(c-primary);
    height: 3.75rem;
    color: color(--white);
    &:active,
    &:focus,
    &:hover {
      border-color: color(c-hover);
    }
  }
  .ant-input-affix-wrapper {
    .ant-input:not(:first-child) {
      padding-left: 2.8125rem;
    }
    .ant-input-prefix {
      color: color(--white);
      font-size: 1.375rem;
      font-family: "Roboto", sans-serif;
    }
  }

  .has-error .ant-input-affix-wrapper .ant-input,
  .has-error .ant-input-affix-wrapper .ant-input:hover {
    background-color: color(c-primary);
  }
}

input:-internal-autofill-selected {
  background-color: color(c-primary) !important;
  border-color: color(c-hover) !important;
  color: black !important;
}

// animation
@keyframes shadowGlow {
  0% {
    box-shadow: 0 0 15px rgba(55, 245, 249, 0.15);
  }
  25% {
    box-shadow: 0 0 15px rgba(55, 245, 249, 0.25);
  }
  50% {
    box-shadow: 0 0 15px rgba(55, 245, 249, 0.35);
  }
  50% {
    box-shadow: 0 0 15px rgba(55, 245, 249, 0.45);
  }
  100% {
    box-shadow: 0 0 15px rgba(55, 245, 249, 0.15);
  }
}
</style>
