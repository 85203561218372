<template>
  <a-layout class="ticket-list">
    <TitleBreadcrumb />
    <div class="content-base container">
      <a-card class="ticket-list-card">
        <a-row type="flex" justify="center" :gutter="[16, 16]">
          <a-col :xs="24">
            <a-input-search
              :placeholder="labels.placeholderSearch"
              style="width: 100%"
              @search="handleSearch"
              allowClear
            />
            <a-divider
              orientation="center"
              type="horizontal"
              :style="{ marginBottom: 0 }"
            />
          </a-col>
        </a-row>
        <a-row type="flex" justify="center" :gutter="[16, 16]">
          <a-col :xs="24">
            <StandardTable
              class="table-base"
              ref="standardTable"
              :fetchService="fetchTickets"
              :columns="columns"
              :locale="locale"
              :handleShowTotal="handleShowTotal"
              :scroll="{ x: isMobile ? true : false }"
              :rowKey="'number'"
              :paginationType="isMobile ? 'arrows' : 'numbers'"
              :customRow="handleCustomRow"
            />
          </a-col>
        </a-row>
      </a-card>
    </div>
  </a-layout>
</template>
<script>
import { mapActions, mapState } from "vuex";
import accounting from "accounting";
import TitleBreadcrumb from "@/components/layout/client/TitleBreadcrumb.vue";
import loadingMixin from "@/mixins/loadingMixin";
import { pageTitle } from "@/utils/utils";
import labels from "@/utils/labels";
import StandardTable from "@/components/core/table/StandardTable.vue";
import { DATE_FORMAT } from "../../../utils/utils";
export default {
  components: { TitleBreadcrumb, StandardTable },
  name: "TicketList",
  mixins: [loadingMixin],
  metaInfo: {
    title: pageTitle(labels.ticketList.subtitle),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.ticketList.subtitle,
      },
    ],
  },
  data() {
    return {
      labels: labels.ticketList,
      search: "",
      loading: false,
      activeRow: null,
    };
  },
  methods: {
    ...mapActions("orders", ["fetchTickets", "cancelTicket"]),
    handleReloadTable() {
      let filtersTable = this.$refs.standardTable.filters;
      let result = { ...filtersTable };
      this.$refs.standardTable.handleFilters(result);
    },
    handleShowTotal(total, range) {
      return `${range[0]}-${range[1]} de ${total}`;
    },
    handleSearch(search) {
      this.$refs.standardTable.handleSearch(search);
    },
    handleConfirmCancellation(record) {
      this.$confirm({
        title: this.labels.confirmCancellation.title,
        content: this.labels.confirmCancellation.content.replace(
          "{code}",
          record.number
        ),
        okText: this.labels.confirmCancellation.okText,
        okType: "danger",
        icon: "close-circle",
        cancelText: this.labels.confirmCancellation.cancelText,
        onOk: async () => {
          this.handleCancellation(record);
        },
      });
    },
    async handleCancellation(record) {
      this.loading = true;
      try {
        await this.cancelTicket(record.number);
        this.$message.success(
          this.labels.confirmCancellation.successCancellation
        );
        this.handleReloadTable();
        this.checkSession();
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message ||
            this.labels.confirmCancellation.errorCancellation
        );
      } finally {
        this.loading = false;
      }
    },
    handleCustomRow(_, index) {
      return {
        on: {
          click: () => {
            this.activeRow = this.activeRow === index ? null : index;
          },
        },
      };
    },
  },
  computed: {
    ...mapState("layout", ["screenWidth"]),
    locale() {
      return {
        emptyText: this.labels.table.emptyText,
      };
    },
    columns() {
      return [
        {
          title: this.labels.table.columns.date,
          dataIndex: "date",
          key: "date",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return this.$moment(text).format(DATE_FORMAT.MOMENT_DATE_TIME);
          },
        },
        {
          title: this.labels.table.columns.code,
          dataIndex: "number",
          key: "number",
          sorter: true,
          hidden: this.isMobile,
        },
        {
          title: this.labels.table.columns.totalAmount,
          dataIndex: "total_amount",
          key: "total_amount",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return accounting.formatNumber(text || 0, 2, ",", ".");
          },
        },
        {
          title: this.labels.table.columns.wonTotalAmount,
          dataIndex: "won_total_amount",
          key: "won_total_amount",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return accounting.formatNumber(text || 0, 2, ",", ".");
          },
        },
        {
          title: this.labels.table.columns.status.title,
          dataIndex: "status",
          key: "status",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return (
              <a-tag color={this.statusColor(text)}>
                {this.statusText(text)}
              </a-tag>
            );
          },
        },
        {
          title: this.labels.table.columns.action.title,
          dataIndex: "action",
          key: "action",
          width: "120px",
          align: "center",
          hidden: this.isMobile,
          customRender: (_, record) => {
            return (
              <div class="action-buttons">
                <router-link
                  to={{
                    name: "ticket-view",
                    params: { code: record.number },
                  }}
                  title={this.labels.table.columns.action.view}
                >
                  <a-button icon="eye" class="action-button-view" />
                </router-link>
                {record?.is_cancellable ? <a-divider type="vertical" /> : null}
                {record?.is_cancellable ? (
                  <a-button
                    icon="close-circle"
                    class="action-button-cancel"
                    title={this.labels.table.columns.action.cancel}
                    onClick={() => this.handleConfirmCancellation(record)}
                    type="danger"
                  />
                ) : null}
              </div>
            );
          },
        },
        {
          title: this.labels.table.columns.tickets,
          dataIndex: "tickets",
          key: "tickets",
          sorter: false,
          hidden: !this.isMobile,
          customRender: (_, record, index) => {
            // Card item design
            const color = this.statusColor(record.status?.toLowerCase());
            return (
              <div class="list-item">
                <div class="list-item-head">
                  <div
                    class="list-item-head-header"
                    style={{ borderColor: color }}
                  >
                    <span>
                      {this.$options.filters.ucwords(
                        this.$moment(record.date).format(
                          DATE_FORMAT.MOMENT_DAY_MONTH
                        )
                      )}
                    </span>
                    <small>
                      {this.$moment(record.date)
                        .format(DATE_FORMAT.MOMENT_TIME)
                        .toUpperCase()}
                    </small>
                  </div>
                  <div class="list-item-head-content">
                    <div class="list-item-head-content-number">
                      <span>{record.number}</span>
                    </div>
                    <div class="list-item-head-content-status">
                      <a-tag color={this.statusColor(record.status)}>
                        {this.statusText(record.status)}
                      </a-tag>
                    </div>
                  </div>
                  <div class="list-item-head-footer">
                    <div class="list-item-head-footer-amount">
                      <span>
                        {accounting.formatNumber(
                          record.total_amount,
                          2,
                          ",",
                          "."
                        )}
                      </span>
                    </div>
                    {record?.won_total_amount ? (
                      <div class="list-item-head-footer-amount">
                        <span class="success">
                          <font-awesome-icon
                            icon={["fas", "hand-holding-dollar"]}
                            class="anticon"
                          />
                          {accounting.formatNumber(
                            record.won_total_amount,
                            2,
                            ",",
                            "."
                          )}
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
                {this.activeRow === index && (
                  <div class="list-item-actions">
                    <a-button-group>
                      <router-link
                        class="view-button ant-btn ant-btn-primary ant-btn-block"
                        to={{
                          name: "ticket-view",
                          params: { code: record.number },
                        }}
                        title={this.labels.table.columns.action.view}
                      >
                        {this.labels.table.columns.action.view}
                      </router-link>
                      {record?.is_cancellable ? (
                        <a-button
                          class="cancel-button ant-btn ant-btn-danger"
                          title={this.labels.table.columns.action.cancel}
                          onClick={() => this.handleConfirmCancellation(record)}
                          type="danger"
                        >
                          {this.labels.table.columns.action.cancel}
                        </a-button>
                      ) : null}
                    </a-button-group>
                  </div>
                )}
              </div>
            );
          },
        },
      ].filter((column) => !column.hidden);
    },
    isMobile() {
      return this.screenWidth < 768;
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.ticket-list {
  max-width: 100% !important;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  @media screen and (max-width: $screen-mobile) {
    max-width: 100% !important;
  }
  .content-base {
    padding-top: 60px;
    @media screen and (max-width: $screen-mobile) {
      max-width: 100% !important;
    }
  }
  &-card {
    background-color: color(c-secondary);
    border-color: color(c-secondary);
    padding: 1.125rem;
    border-radius: 10px;
    @media screen and (max-width: $screen-mobile) {
      padding: 2.125rem 1rem;
      width: 100%;
    }
    .ant-input {
      box-shadow: 0 1px 4px rgba(255, 255, 255, 0.05) !important;
      border: 1px solid color(--white) !important;
      color: color(--white) !important;
      font-family: "Exo 2", sans-serif !important;
      background-color: transparent;
    }
    .ant-input-search-icon {
      color: color(--white) !important;
      transition: all 0.5s ease;
      :hover {
        color: color(c-hover) !important;
        transition: all 0.5s ease;
      }
    }
    .ant-input-clear-icon {
      color: color(--white) !important;
      transition: all 0.5s ease;
      :hover {
        color: color(danger) !important;
        transition: all 0.5s ease;
      }
    }
    .ant-table {
      &:hover {
        transition: 0.5s ease;
        background: unset;
      }
      tr.ant-table-row:hover {
        transition: 0.5s ease;
        background: rgba(0, 0, 0, 0.25) !important;
      }
      tr:nth-child(2n) {
        transition: 0.5s ease;
        background: rgba(0, 0, 0, 0.2) !important;
      }
      tr.table-row-disabled,
      tr.table-row-disabled:hover {
        color: color(c-primary);
        background: color(danger-light) !important;
        font-weight: 600;
      }
      .ant-table-tbody > tr.ant-table-row:hover > td {
        transition: 0.5s ease;
        background: none !important;
      }
      &-tbody,
      &-thead {
        color: color(--white) !important;
        tr {
          th {
            color: color(--white) !important;
            background-color: transparent !important;
          }
        }
      }
      &-footer {
        background: transparent;
      }
      .action-button-view {
        color: color(c-hover);
        border-color: color(c-hover);
        transition: all 0.5s ease;
        background-color: color(c-secondary);
        &:hover {
          transition: all 0.5s ease;
          color: color(c-secondary);
          background-color: color(c-hover);
          border-color: color(c-hover);
        }
      }
      .ant-table-placeholder {
        background: color(c-secondary);
        color: color(--white);
        border-radius: 0;
      }
      @media screen and (max-width: $screen-mobile) {
        &-small {
          border: none;
        }
        &-body {
          margin: 0 1px;
        }
      }
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      color: color(c-secondary) !important;
      background-color: color(c-hover) !important;
      border-color: color(c-hover) !important;
    }
    .ant-pagination-item {
      background-color: color(c-hover) !important;
      border-color: color(c-secondary) !important;
      a {
        color: color(c-secondary) !important;
      }
      &-active {
        background-color: color(c-secondary) !important;
        border-color: color(c-hover) !important;
        a {
          color: color(c-hover) !important;
        }
      }
    }
    .ant-pagination {
      color: color(--white) !important;
    }
  }
  .list-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 6px;
      &-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        max-width: 25%;
        width: 25%;
        border-right: 4px solid color(border);
        .success {
          color: color(success);
        }
        .warning {
          color: color(warning);
        }
        .danger {
          color: color(danger);
        }
        span {
          font-size: 14px;
          font-weight: 600;
        }
        small {
          font-size: 12px;
          font-weight: 400;
          padding-right: 4px;
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        max-width: 30%;
        width: 30%;
        overflow: hidden;
        &-number {
          span {
            font-size: 16px;
            font-weight: 600;
            text-overflow: ellipsis;
          }
        }
        &-status {
          span {
            font-size: 12px;
            font-weight: 400;
            text-overflow: ellipsis;
          }
        }
      }
      &-footer {
        max-width: 45%;
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: right;
        gap: 2px;
        &-amount {
          width: 100%;
          span {
            width: 100%;
            font-size: 14px;
            font-weight: 600;
            &.success {
              color: color(success);
            }
            .anticon {
              margin-right: 6px;
            }
          }
          &:first-child {
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
    &-actions {
      width: 100%;
      margin-top: 10px;
      .ant-btn-group {
        width: 100%;
        .more-info-button,
        .view-button,
        .cancel-button {
          width: 100%;
        }
        .more-info-button {
          background-color: transparent;
          border-color: color(c-hover);
          color: color(c-hover);
          &:active,
          &:focus,
          &:hover {
            background-color: transparent;
            border-color: color(c-hover);
            color: color(c-hover);
          }
        }
        .view-button {
          background-color: transparent;
          border-color: color(c-hover);
          color: color(c-hover);
          &:active,
          &:focus,
          &:hover {
            background-color: transparent;
            border-color: color(c-hover);
            color: color(c-hover);
          }
        }
      }
    }
  }
}
</style>
