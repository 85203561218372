<template>
  <a-layout class="verify-phone">
    <TitleBreadcrumb />
    <div class="content-base container">
      <a-row type="flex" justify="center">
        <a-col :md="10">
          <VerifyPhoneForm />
        </a-col>
      </a-row>
    </div>
  </a-layout>
</template>
<script>
import loadingMixin from "@/mixins/loadingMixin";
import TitleBreadcrumb from "@/components/layout/client/TitleBreadcrumb";
import VerifyPhoneForm from "@/components/register/VerifyPhoneForm.vue";
import { pageTitle } from "../../../utils/utils";
import labels from "@/utils/labels";
export default {
  components: { TitleBreadcrumb, VerifyPhoneForm },
  name: "VerifyPhone",
  mixins: [loadingMixin],
  metaInfo: {
    title: pageTitle(labels.verifyPhoneForm.title),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.verifyPhoneForm.title,
      },
    ],
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.verify-phone {
  max-width: 100% !important;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  @media screen and (max-width: $screen-mobile) {
    max-width: 100% !important;
  }
  .content-base {
    padding-top: 60px;
    @media screen and (max-width: $screen-mobile) {
      max-width: 100% !important;
    }
  }
}
</style>
